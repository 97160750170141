import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <> <div>
  <div className="vh-100 d-flex justify-content-center align-items-center">
    <div className="card col-md-5 bg-white shadow-md p-5">
      <div className="mb-5 text-center">
      <img src={require('../../logo.png')} style={{width: '40%'}} alt="" />
      </div>
      <div className="text-center">
        <h1>Login</h1>
        <h5>Please login to manage non-booking payments</h5>
        <Button onClick={() => loginWithRedirect()}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
      </div>
    </div>
  </div></div>
);</>    
  
  
};

export default LoginButton;