import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { GeneratePaymentLink } from './Components/GeneratePaymentLink/GeneratePaymentLink';
import { FexcoNavbar } from './Components/Navbar/FexcoNavbar';
import PaymentRecords from './Components/PaymentRecord/PaymentRecords';
import Success from './Components/helpers/Success';
import Error from './Components/helpers/Error';
import SignIn from './Components/helpers/SignIn';
import { useAuth0 } from "@auth0/auth0-react";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate replace to="/login" />,
  },
  {
    path: "/success",
    element: <Success/>,
  },
  {
    path: "/error",
    element: <Error/>,
  },
  {
    path: "/login",
    element: <SignIn/>,
  },
  {
    path: "/paymentlink",
    element: <><FexcoNavbar/><GeneratePaymentLink /></>,
  },
  {
    path: "/paymentrecords",
    element: <><FexcoNavbar/><PaymentRecords /></>,
  },
]);

function App() {

  const { isAuthenticated, isLoading } = useAuth0();

  let element = handlePublicHosts();
  if(element)
  return element;

  if (!isLoading && !isAuthenticated) {
    return <SignIn/>;
  }
  return (
  <>
    <RouterProvider router={router} />
    </>)
}

const handlePublicHosts = () => {
  let currentpath = getCurrentPath();
  if(publicPathNames.includes(currentpath)){
    if(currentpath === succesPathname)
    return <Success/>
    if(currentpath === errorPathname)
    return <Error/>
  }
}

const getCurrentPath = () => {
return window.location.pathname;
}

const succesPathname = '/success';
const errorPathname = '/error';
const publicPathNames = [succesPathname, errorPathname];

export default App;
