import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { Button } from 'react-bootstrap';

export function PaymentRecordColumns(onPaymentLinkclick: any, onDeleteClick: any): GridColDef[] {
  return [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 25,
      renderCell: (params) => {
        return (
          <IconButton aria-label="delete" size="small" onClick={() => onDeleteClick(params)}>
            <DeleteIcon />
          </IconButton>
        );
      }
    },
    { field: 'accountNumberFormatted', headerName: 'Account Number', width: 160 },
    { field: 'reservationNumber', headerName: 'Reservation Number', width: 120 },
    { field: 'voyageNumber', headerName: 'Voyage Number', width: 120 },
    { field: 'paymentDescription', headerName: 'Payment Description', width: 200 },
    { field: 'payeeName', headerName: 'Payee Name', width: 150 },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 70,
      sortable: false
    },
    { field: 'company', headerName: 'Company', width: 60, sortable: false },
    { field: 'currency', headerName: 'Currency', width: 60, sortable: false },
    { field: 'isPaid', headerName: 'IsPaid', type: 'boolean', width: 110 },
    {
      field: 'paymentLink', headerName: 'Payment Link', width: 170,
      renderCell: (params) => {
        return (
          <Button variant='text' size='sm' onClick={() => onPaymentLinkclick(params)}>{params.row.paymentLink}</Button>
        );
      }
    },
    {
      field: 'fexcoTransactionDatetime', headerName: 'Transaction time', width: 170
    },
    // {
    //   field: 'fexcoTransactionDatetime', headerName: 'Transaction time', type: 'string', width: 170, valueFormatter: function (params) {
    //     return params.value ? moment(params.value).format('DD/MM/YYYY hh:mm:ss').toString() + ' EST' : null;
    //   }
    // },
    { field: 'creditCard', headerName: 'Credit Card', width: 130 },
  ];
}
