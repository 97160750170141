
export default function Success() {
  
  const queryParameters = new URLSearchParams(window.location.search)

  const id = queryParameters.get("id")
  const amount = queryParameters.get("amount")
  const transactionDate = queryParameters.get("date")

console.log(transactionDate)
    return <> <div>
    <meta charSet="UTF-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="card col-md-5 bg-white shadow-md p-5">
        <div className="mb-5 text-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width={75} height={75} fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </div>
        <div className="text-center">
          <h1>Thank You !</h1>
          <p><h5>Your payment has been processed successfully</h5> </p>
          {id ? ( <p><h5>Transaction ID: {id} </h5> </p>) : (<></>)}
          {amount ? ( <p><h5>Amount: {amount} </h5> </p>) : (<></>)}
          {transactionDate ? ( <p><h5>Transaction Date: {transactionDate} </h5> </p>) : (<></>)}

          <button onClick={() => window.print()} className="btn btn-outline-success">Print</button>
        </div>
      </div>
    </div></div>
);</>    
  }