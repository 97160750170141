import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Profile from '../helpers/Profile';

export function FexcoNavbar() {
  
    return (
        <>
       <Navbar bg="light" expand="lg">
      <Container>
        
      <img src={require('../../logo.png')} style={{width: '10%'}} alt="" />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/paymentlink">Create Payment</Nav.Link>
            <Nav.Link href="/paymentrecords">All payments</Nav.Link>
          </Nav>
        <Profile/>
        </Navbar.Collapse>
        {/* <LogoutButton/> */}
      </Container>
    </Navbar>
      </>
    );
}