import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSearchParams } from 'react-router-dom';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import { Callback } from './Callback';
import { PaymentRecordColumns } from "./PaymentRecordColumns"

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export interface PaymentRecord {
  id: string
  serviceDescription: string
  lindBladref: string
  switch: string
  agentdetail: string
  amount: number
  currency: string
  paymentToken: string
  paymentLink: string
  transactionDatetime: string
  fexcoTransactionDatetime: string
  isPaid: boolean
  isFailed: boolean
  callbackPaymentJson: string
  creditCard: string
}
export default function PaymentRecords() {
  const [paymentRecords, setPaymentRecords] = React.useState<PaymentRecord[] | any>();
  const [paymentLink, setPaymentLink] = React.useState<string>();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let search = searchParams.get("search");

  const { getAccessTokenSilently, logout } = useAuth0();

  const onPaymentLinkclick = (params: any) => {
    setPaymentLink(params.row.paymentLink)
    setTimeout(async () => await navigator.clipboard.writeText(paymentLink !== undefined ? paymentLink : ''))
    setOpen(true);
  }

  const onDeleteClick = async (params: any) => {
    if (params.row.isPaid) {
      return alert('Cannot delete the already paid record.');
    }

    if (window.confirm('Are you sure you want to delete this record?')) {
      try {
        const accessToken = await getAccessTokenSilently();

        const config = {
          headers: { Authorization: `Bearer ${accessToken}` },
        };
        let baseurl = process.env.REACT_APP_NOONBOOKING_URL;
        if (!baseurl)
          console.error("process.env.REACT_APP_NOONBOOKING_URL not set");

        axios.post(baseurl + `/FexcoPayment/Delete?id=${params.row.id}`, { id: params.row.id }, config)
          .then(response => {
            if (response.data != 'ok') {
              return alert('There was issue deleting the request: ' + response.data);
            }

            fetchData();
          })
          .catch(e => {
            if (e && e.response && e.response.status === 403) {
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
            else {
              console.log(e);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    GetAndSetPaymentRecords(search, setPaymentRecords);
  }, [])

  const onSubmit = (event: any) => {
    event.preventDefault();
    var searchval = event.target.search.value;
    if (searchval) {
      setSearchParams({ search: searchval });
      GetAndSetPaymentRecords(searchval, setPaymentRecords);
    }
  }

  async function GetAndSetPaymentRecords(searchparam: string | null, setPaymentRecords: React.Dispatch<any>) {
    try {
      const accessToken = await getAccessTokenSilently();

      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      let baseurl = process.env.REACT_APP_NOONBOOKING_URL;
      if (!baseurl)
        console.error("process.env.REACT_APP_NOONBOOKING_URL not set");

      axios.get<PaymentRecord[]>(baseurl + "/FexcoPayment/GetAll?search=" + searchparam, config)
        .then(response => {
          response.data.forEach(function (element) {
            let deserialize: Callback = JSON.parse(element.callbackPaymentJson);
            element.creditCard = deserialize?.card_token?.masked_pan
            if (deserialize?.ResponseTimestampText !== undefined)
              element.fexcoTransactionDatetime = deserialize?.ResponseTimestampText + ' EST';
          })
          setPaymentRecords(response.data)
        })
        .catch(e => {
          if (e && e.response && e.response.status === 403) {
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
          else {
            console.log(e);
          }
        })
    } catch (e) {
      console.log(e);
    }
  }

  async function ExportToExcel() {
    try {
      const accessToken = await getAccessTokenSilently();

      let baseurl = process.env.REACT_APP_NOONBOOKING_URL;
      if (!baseurl)
        console.error("process.env.REACT_APP_NOONBOOKING_URL not set");
      axios.post<any>(baseurl + "/FexcoPayment/excel?search=" + search, null, {
        headers:
        {
          Authorization: `Bearer ${accessToken}`,
          "Access-Control-Allow-Origin": "*",
          'Content-Disposition': "attachment; filename=records.csv",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'records.csv');
          document.body.appendChild(link);
          link.click();
        });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>

      <Container  >
        <Form onSubmit={onSubmit} className="d-flex">
          <Form.Control style={{ width: '80%' }}
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            name="search"
          />
          <Button style={{ marginRight: '0.5rem' }} type="submit" variant="outline-success">Search</Button>{' '}
          <Button type="button" onClick={ExportToExcel} variant="outline-secondary">Export</Button>{' '}
        </Form>
      </Container> <div className="d-grid gap-2">

      </div>
      <div style={{ height: 900, width: '100%' }}>
        <DataGrid
          rows={paymentRecords ?? []}
          columns={PaymentRecordColumns(onPaymentLinkclick, onDeleteClick)}
          disableRowSelectionOnClick
          getRowClassName={(params) => params.row.isPaid ? 'bg-success-subtle' : ''}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            {/* Payment Link */}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>

            <Card style={{ display: paymentLink !== undefined ? 'flex' : 'none' }}>
              <Card.Body>
                <Card.Title> Click below to copy or continue to checkout page</Card.Title>
                <Card.Text>
                </Card.Text>
                <Button variant="primary" href={"" + paymentLink + ""} target='_blank'>Pay Now</Button>{'   '}
                <Button variant="secondary" onClick={() => {
                  navigator.clipboard.writeText(paymentLink !== undefined ? paymentLink : '');
                }}> Copy Link</Button>
              </Card.Body>

            </Card>


          </Typography>
        </Box>
      </Modal>
    </>
  );
}