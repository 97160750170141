
export default function Error() {
  const queryParameters = new URLSearchParams(window.location.search)

  const id = queryParameters.get("id")
  const amount = queryParameters.get("amount")
  const transactionDate = queryParameters.get("date")
    return <>
    
    <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="text-center">
      {/* <h1 className="display-1 fw-bold">404</h1> */}
      {id ? ( <p><h5>Transaction ID: {id} </h5> </p>) : (<></>)}
          {amount ? ( <p><h5>Amount: {amount} </h5> </p>) : (<></>)}
          {transactionDate ? ( <p><h5>Transaction Date: {transactionDate} </h5> </p>) : (<></>)}

      <p className="fs-3"> <span className="text-danger">Opps!</span> Something went wrong.</p>
      <p className="lead">
        Please try later.
      </p>
      <button onClick={() => window.print()} className="btn btn-outline-success">Print</button>
    </div>
  </div>
  </>    
  } 