import { useState } from "react";
import { useForm } from "react-hook-form";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


interface IFormValues  {
  serviceDescription: string,
  linbladref: string,
  agentdetail: string,
  amount: number
};

export interface ErrorMessages {
  DefaultMessage: string[]
  Company: string[]
  VoyageNumber: string[]
  AccountNumber: string[]
  ReservationNumber: string[]
}


export function GeneratePaymentLink() {
  
const { register, handleSubmit } = useForm();
const [paymentLink, setPaymentLink] = useState<string>();
const [errorText, setErrorText] = useState<string>();
const { getAccessTokenSilently } = useAuth0();
const isProdMode = (): boolean => {
  if (process.env.NODE_ENV === 'production') {
    return true;
  }
  return false;
}
const onSubmit = async (data : IFormValues|any) => {
  const accessToken = await getAccessTokenSilently();
        
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` }
};

let baseurl = process.env.REACT_APP_NOONBOOKING_URL;
if(!baseurl)
  console.error("process.env.REACT_APP_NOONBOOKING_URL not set");

  axios
  .post<string>(baseurl + "/FexcoPayment", data, config)
  .then(response => 
    setPaymentLink(response.data))
    .catch(err => {
      if (err.response.data.errors){
        const error: ErrorMessages = err.response.data.errors;
        setErrorText(ErrorMessagePrettify(error));
      }
      else
      console.log(err.response)
    });
}

  function setShow(arg0: boolean): void {
    setErrorText(undefined);
  }

  function ErrorMessagePrettify(error: ErrorMessages): string {
    return (error.AccountNumber ? error.AccountNumber.join(', ') : '')
      + ' ' + (error.Company ? error.Company.join(', ') : '')
      + ', ' + (error.ReservationNumber ? error.ReservationNumber.join(', ') : '')
      + ', ' + (error.DefaultMessage ? error.DefaultMessage.join(', ') : '')
      + ' ' + (error.VoyageNumber ? error.VoyageNumber.join(', ') : '');
  }

    return (
<>
        <Container className="p-5 mb-4 bg-light rounded-3">
        <Form  onSubmit={handleSubmit(onSubmit)}>

        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Account Number*</Form.Label>
          <Form.Control placeholder="XXXX-X-XX-XX-XXXX" {...register("accountNumber", { required: true})}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Reservation Number</Form.Label>
          <Form.Control type="number" placeholder="Reservation Number" {...register("reservationNumber")}/>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Voyage Number</Form.Label>
          <Form.Control  placeholder="Voyage Number" {...register("voyageNumber")}/>
        </Form.Group>

        <Row className="mb-3">
        <Form.Group className="mb-3" controlId="formGridAddress1">
          <Form.Label>Payment Description*</Form.Label>
          <Form.Control placeholder="Payment Description" {...register("PaymentDescription", { required: true})}/>
        </Form.Group>

          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Payee Name*</Form.Label>
            <Form.Control {...register("PayeeName", { required: true})} />
          </Form.Group>
  
            <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Amount*</Form.Label>
            <Form.Control  placeholder="$" type="number" step="any" {...register("Amount", { required: true, valueAsNumber: true})}/>
          </Form.Group>
        </Row>
      
        <Form.Label>Company* </Form.Label>
        <Form.Group className="mb-4">
        <Form.Check
            inline
            label="LEX"
            type= "radio"
            value="LEX"
            id= "inline-1" {...register("Company")}
          />
          <Form.Check
            inline
            label="LME"
            type="radio"
            value="LME"
            id= "inline-2" {...register("Company")}
          />
      </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
      <br></br>
      <Card style={{display: paymentLink !== undefined ? 'flex' : 'none' }}>
      <Card.Body>
        <Card.Title> Click below to copy or continue to checkout page</Card.Title>
        <Card.Text>
        </Card.Text>
        <Button variant="primary" href={"" + paymentLink + ""} target="_blank">Pay Now</Button>{'   '}
        <Button  variant="secondary" onClick={() => {
         navigator.clipboard.writeText(paymentLink !== undefined ? paymentLink : '');}}> Copy Link</Button>
      </Card.Body>
    </Card>

    <Alert style={{display: errorText !== undefined ? 'flex' : 'none' }} variant="danger" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>{errorText}</Alert.Heading>
      </Alert>

</Container>
</>
    );
}